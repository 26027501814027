html {
  overflow-y: scroll;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.HomePage {
  max-width: 90%;
}

.FaqPage {
  max-width: 75%;
}

.carousel-item {
  height: 50vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hr-short {
  max-width: 40%;
}

.hr-wide {
  max-width: 60%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.MuiInputBase-input {
  text-align: center; /* Aligns text in date/time pickers of material-ui*/
}

.export-pagebreak {
  page-break-after: always;
}

.export-table {
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
  border: 1px solid black;
  border-collapse: collapse;
}

.export-page {
  width: 100%;
  max-width: 100%;
}
.profile-avatar {
  max-width: 40px;
  background-color: lightgray;
}

.basic-icon {
  max-width: 20px;
}

.dropdown-menu.show {
  left: -75px;
}

.image-locked {
  max-width: 25px;
  background-color: yellow;
}

.custom-deletebutton:enabled {
  background-color: #dc3545;
}

.custom-deletebutton-hover:hover {
  background-color: #f0a8af;
}

.custom-lockbutton:enabled {
  background-color: #ffc107;
}

.custom-lockbutton-hover:hover {
  background-color: #ffe699;
}

.custom-primarybutton-hover:hover {
  background-color: #99caff;
}

.custom-addbutton-hover:hover {
  background-color: #99caff;
}

.custom-dangerbutton-hover:hover {
  background-color: #f0a8af;
}

.custom-successbutton-hover:hover {
  background-color: #adebbb;
}

.custom-adddropdown {
  color: black;
}

.custom-flaticon-blue-background-color {
  background-color: #3fa1e0;
}
.custom-flaticon-blue-color {
  color: #3fa1e0;
}

.custom-button-rounded-corners {
  border: 0px;
  border-radius: 40px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-img-tiny {
  max-width: 15%;
}

.custom-img-small {
  max-width: 27%;
}

.custom-img-medium {
  max-width: 35%;
}

.custom-img-large {
  max-width: 45%;
}
